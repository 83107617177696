<div class="bus-photo-uploader">
  <nib-file-uploader
    [accept]="'image/*'"
    [icon]="icon"
    [progress]="uploadProgress$ | async"
    [value]="photo"
    (onFileDropped)="onPhotoFileAdded.emit($event.success[0])"
    class="bus-photo-uploader-file-uploader"
  ></nib-file-uploader>
  <div class="bus-photo-uploader-content">
    <button
      *ngIf="uploadLabel"
      (click)="openFile()"
      class="bus-photo-uploader-action"
      type="button"
    >
      {{ uploadLabel }}
    </button>
    <span *ngIf="uploadCaption" class="nib-small">{{ uploadCaption }}</span>
    <ng-content></ng-content>
  </div>
</div>
