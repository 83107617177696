import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUploaderComponent, Icon } from '@nibol/ui';
import { of } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bus-photo-uploader',
  styleUrls: ['photo-uploader.component.scss'],
  templateUrl: 'photo-uploader.component.html',
})
export class PhotoUploaderComponent {
  @Input() icon: Icon = 'upload';
  @Input() photo: string | undefined;
  @Input() uploadCaption: string | undefined;
  @Input() uploadLabel: string | undefined;

  @Output() onChangePhotoClick = new EventEmitter();
  @Output() onPhotoFileAdded = new EventEmitter<File>();

  @ViewChild(FileUploaderComponent) fileUploader!: FileUploaderComponent;

  uploadProgress$ = of(0);

  openFile(): void {
    this.fileUploader.fileInput.nativeElement.click();
  }
}
