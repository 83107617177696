import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploaderModule, ImageManagerModule } from '@nibol/ui';
import { PhotoUploaderComponent } from './photo-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    FileUploaderModule.forRoot({ translations: { description: '', title: '' } }),
    ImageManagerModule,
  ],
  exports: [PhotoUploaderComponent],
  declarations: [PhotoUploaderComponent],
})
export class PhotoUploaderModule {}
