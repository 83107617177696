import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { CompanySelectors } from '@business/store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionFeatureExistsToggleGuard implements CanActivate {
  constructor(private readonly store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.selectOnce(
      CompanySelectors.existsFeature(route.data.subscriptionFeatureToggle),
    );
  }
}
